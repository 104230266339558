import { Box, Grid, IconButton, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { mainColor, secondaryColor } from "../util/MuiTheme";
import LogoSantaFara from "./LogoSantaFara";
import { EmailRounded, FacebookRounded, Phone, YouTube } from "@mui/icons-material";
import logoDiocesiImg from "../img/logoDiocesi.png";
import santaFaraFooterImg from "../img/santaFaraFooter.png";
import ibanFooterImg from "../img/ibanFooter.png";
import bancoOpereCaritaImg from "../img/bancoOpereCarita.jpeg";
import LogoWongBianco from "../img/LogoWongBianco.png";
import { getOrariFeriale, getOrariFestivo } from "../util/Constants";

const Footer = () => {
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (<>
        {isLargeScreen ? <>
            <Paper style={{ height: "500px", backgroundImage: `url(${bancoOpereCaritaImg})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}/>
            <Paper style={{ minHeight: "500px", padding: "50px", backgroundImage: `url(${ibanFooterImg})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                <Typography variant="h1" fontWeight="bold">Sostienici</Typography>
                <Typography variant="h3" color="white">con una donazione al nostro iban</Typography>
                <Box bgcolor={mainColor} padding="20px" borderRadius="20px" marginTop="100px" marginLeft="25%" marginRight="25%">
                    <Typography variant="h3" color="white">IBAN: IT41W0846904001000000141232</Typography>
                    <Typography variant="h3" color="white">Intestatario: Parrocchia Santa Fara</Typography>
                </Box>
            </Paper>
            <Paper style={{ minHeight: "650px", backgroundImage: (`url(${santaFaraFooterImg})`), backgroundSize: "cover", backgroundPosition: "center" }}>
                <Typography variant="h1" fontWeight="bold" paddingTop="400px" color="white">Camminiamo insieme</Typography>
            </Paper>
            <Box bgcolor={secondaryColor} padding={2}>
                <Grid container>
                    <Grid item xs={3}>
                        <LogoSantaFara logoTitleDistance={0} />
                    </Grid>
                    <Grid item xs={2}>
                        <Stack direction="row">
                            <img style={{ height: "90px" }} src={logoDiocesiImg} alt="Logo diocesi" />
                            <Typography variant="h3" fontFamily="Franciscus" color="white" textAlign="left" paddingLeft={2}>Arcidiocesi<br />Bari<br />Bitonto</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="white" fontWeight="bold" paddingBottom={1}>CONTATTI</Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <a href="mailto:parroco@santafara.org">
                                    <IconButton color="primary">
                                        <EmailRounded />
                                        <Typography paddingLeft={1}>parroco@santafara.org</Typography>
                                    </IconButton>
                                </a>
                                <a href="https://www.facebook.com/SantaFaraBari">
                                    <IconButton color="primary">
                                        <FacebookRounded />
                                        <Typography paddingLeft={1}>Basilica Santa Fara</Typography>
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href="tel:0805618236">
                                    <IconButton color="primary">
                                        <Phone />
                                        <Typography paddingLeft={1}>0805618236</Typography>
                                    </IconButton>
                                </a>
                                <a href="https://www.youtube.com/@basilicasantafara1958">
                                    <IconButton color="primary">
                                        <YouTube />
                                        <Typography paddingLeft={1}>Basilica Santa Fara</Typography>
                                    </IconButton>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography color="white" fontWeight="bold" paddingBottom={1}>ORARI SANTE MESSE</Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography>Feriale<br />{getOrariFeriale(1)}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Festivo<br />{getOrariFestivo(1)}<br />{getOrariFestivo(2)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography paddingBottom={1} color="white">Tutti i diritti sono riservati a - Basilica Parrocchia Santa Fara - Bari</Typography>
                        <div style={{ display:'flex', justifyContent:'center', color:'white'}}>
                            powered by <img src={LogoWongBianco} style={{ width: '30px' }} alt="Logo Wong"/>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </> : <>
            <Paper style={{ height: "230px", backgroundImage: `url(${bancoOpereCaritaImg})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}/>
            <Paper style={{ minHeight: "230px", padding: "20px", backgroundImage: `url(${ibanFooterImg})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                <Typography variant="h2" fontWeight="bold">Sostienici</Typography>
                <Typography variant="h4" color="white">con una donazione al nostro iban</Typography>
                <Box bgcolor={mainColor} padding="10px" borderRadius="10px" marginTop="40px" marginLeft="10px" marginRight="10px">
                    <Typography variant="paragraph" color="white" fontSize="14px">IBAN: IT41W0846904001000000141232</Typography><br/>
                    <Typography variant="paragraph" color="white" fontSize="14px">Intestatario: Parrocchia Santa Fara</Typography>
                </Box>
            </Paper>
            <Paper style={{ minHeight: "170px", backgroundImage: (`url(${santaFaraFooterImg})`), backgroundSize: "cover", backgroundPosition: "center" }}>
                <Typography variant="h2" fontWeight="bold" paddingTop="80px" color="white">Camminiamo insieme</Typography>
            </Paper>
            <Box bgcolor={secondaryColor} padding={2}>
                <Grid container columnSpacing={3} rowSpacing={2}>
                    <Grid item xs={6}>
                        <LogoSantaFara logoTitleDistance={1} />
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="row">
                            <img style={{ height: "70px" }} src={logoDiocesiImg} alt="Logo diocesi" />
                            <Typography variant="h4" fontFamily="Franciscus" color="white" textAlign="left" paddingLeft={1}>Arcidiocesi<br />Bari<br />Bitonto</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontWeight="bold" color="white" paddingBottom={1}>ORARI SANTE MESSE</Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography>Feriale<br />{getOrariFeriale(1)}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Festivo<br />{getOrariFestivo(1)}<br />{getOrariFestivo(2)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontWeight="bold" color="white" paddingBottom={1}>CONTATTI</Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                <a href="mailto:parroco@santafara.org">
                                    <IconButton color="primary">
                                        <EmailRounded />
                                        <Typography paddingLeft={1}>parroco@santafara.org</Typography>
                                    </IconButton>
                                </a>
                                <br/>
                                <a href="https://www.facebook.com/SantaFaraBari">
                                    <IconButton color="primary">
                                        <FacebookRounded />
                                        <Typography paddingLeft={1}>Basilica Santa Fara</Typography>
                                    </IconButton>
                                </a>
                                <br/>
                                <a href="tel:0805618236">
                                    <IconButton color="primary">
                                        <Phone />
                                        <Typography paddingLeft={1}>0805618236</Typography>
                                    </IconButton>
                                </a>
                                <br/>
                                <a href="https://www.youtube.com/@basilicasantafara1958">
                                    <IconButton color="primary">
                                        <YouTube />
                                        <Typography paddingLeft={1}>Basilica Santa Fara</Typography>
                                    </IconButton>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography paddingBottom={1} style={{fontSize:'10px'}} color="white">Tutti i diritti sono riservati a<br/> - Basilica Parrocchia Santa Fara - Bari</Typography>
                        <div style={{ display:'flex', justifyContent:'center', color:'white',fontSize:'10px'}}>
                            powered by <img src={LogoWongBianco} style={{ width: '20px' }} alt="Logo Wong"/>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </>}
    </>);
}

export default Footer;